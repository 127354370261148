<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-01 12:54:15
 * @ Description: Component displaying the public general profile industries/job titles tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Industries/Job Titles -->
                <v-col
                    v-if="profileIndustryJobTitles === null || profileIndustryJobTitles.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No <span v-if="isCompany">industries</span><span v-else-if="isIndividual">job titles</span> added
                </v-col>
                <!-- No Industries/Job Titles -->

                <!-- Industries/Job Titles -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in profileIndustryJobTitles"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="88px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                        v-if="isCompany"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="12"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiDomain }}
                                </v-icon>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0 text-truncate"
                                cols="12"
                            >
                                {{ item.industry.name }}
                            </v-col>
                            <!-- <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Job Title:
                                </div>
                                <div
                                    class="os-12-sb text-truncate"
                                    style="color: #2E567A"
                                >
                                    {{ item.job_title.name }}
                                </div>
                            </v-col> -->
                        </v-row>
                    </v-card>

                    <v-card
                        height="144px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                        v-else-if="isIndividual"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="12"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiDomain }}
                                </v-icon>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0"
                                cols="12"
                            >
                                {{ item.job_title.name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Industry:
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    {{ item.industry.name }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Industries/Job Titles -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        <span v-if="isCompany">All Industries</span>
                        <span v-else-if="isIndividual">All Job Titles</span>
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                                v-model="search"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- Industries/Job Titles -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="industryJobTitlesHeaders"
                        :items="profileIndustryJobTitles"
                        item-key="id"
                        hide-default-footer
                        :no-data-text="noDataText"
                        :no-results-text="noResultsText"
                        :search="search"
                    >
                        <template v-slot:[`item.industry`]="{ item }">
                            {{ item.industry.name }}
                        </template>
                        <template v-slot:[`item.job_title`]="{ item }">
                            {{ item.job_title.name }}
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Industries/Job Titles -->
            </v-row>
            <!-- List View -->
        </v-col>
    </v-row>
</template>
<script>
    import { mdiMagnify, mdiDomain } from '@mdi/js'
    import { GeneralProfileCategoryController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfilePublicIndustriesJobTitlesTabComponent',

        props: {
            selectedView: {
                type: String,
                required: true
            },

            companyUserID: {
                type: Number
            }
        },

        watch: {
            companyUserID: {
                async handler (value) {
                    if (value) await this.getProfileIndustryJobTitles(value)
                },
                deep: true
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                if (this.isCompany) return 'No industries added'
                else return 'No job titles added'
            },

            industryJobTitlesHeaders () {
                if (this.isIndividual) {
                    return [
                        {
                            text: 'Industry',
                            value: 'industry.name'
                        },
                        {
                            text: 'Title',
                            value: 'job_title.name'
                        }
                    ]
                } else {
                    return [
                        {
                            text: 'Industry',
                            value: 'industry.name'
                        }
                    ]
                }
            }
        },

        data () {
            return {
                mdiMagnify,
                mdiDomain,
                profileIndustryJobTitles: [],
                generalProfileCategoryController: null,
                search: '',
                isIndividual: false,
                isCompany: false
            }
        },

        beforeMount () {
            this.generalProfileCategoryController = new GeneralProfileCategoryController()
        },

        async mounted () {
            if (this.$route.path.includes('individual')) {
                this.isIndividual = true
                await this.getProfileIndustryJobTitles(this.$route.params.id)
            } else if (this.$route.path.includes('company') && this.companyUserID) {
                this.isCompany = true
                await this.getProfileIndustryJobTitles(this.companyUserID)
            }
        },

        methods: {
            async getProfileIndustryJobTitles (id) {
                try {
                    this.profileIndustryJobTitles = await this.generalProfileCategoryController.retrieve(id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
